<template lang="pug">
  .box(style='width:990px')
    Loader(v-if='loading')
    Order(
      :document='document'
      v-else
    )
    ErrorModal(
      v-if='hasError'
      :error='error'
      :retry='retry'
    )



</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Order from "@/components/Order.vue";
import ErrorModal from "@/components/ErrorModal.vue";

import axios from "axios";
import config from "@/config.js";

export default {
  data: () => ({
    document: null,
    loading: true,
    hasError: false,
    error: {
      name: "Неизвестная ошибка",
      message: "Произошла неизвестная ошибка"
    }
  }),
  computed: {
    ...mapGetters({
      formData: "form/data",
      serviceId: "services/current"
    })
  },
  components: {
    Order,
    ErrorModal
  },
  methods: {
    ...mapMutations("document", ["setId", "setPaymentPath", "setQrcodePath"]),
    ...mapMutations("stages", ["setCurrent", "setFreeState", "setPriceState"]),
    loadDocument() {
      let data = { ...this.formData };

      // const bracersFields = [
      //   "countExcerptAccountCurrentWords",
      //   "countExcerptAccountCurrentACWords",
      //   "countExcerptAccountCurrentCountACWords",
      //   "countExcerptAccountDateWords",
      //   "countExcerptOperationsPeriodWords",
      //   "countNotifyNextOperationWords",
      //   "countExcerptStocksWords",
      //   "countReferenceOtherWords",
      // ];

      // bracersFields.forEach(field => {
      //   if (data[field]) {
      //     data[field] = ` (${data[field]})`;
      //   }
      // });

      // const concatFields = {
      //   countExcerptAccountCurrent: "countExcerptAccountCurrentWords",
      //   countExcerptAccountCurrentAC: "countExcerptAccountCurrentACWords",
      //   countExcerptAccountCurrentCountAC: "countExcerptAccountCurrentCountACWords",
      //   countExcerptAccountDate: "countExcerptAccountDateWords",
      //   countExcerptOperationsPeriod: "countExcerptOperationsPeriodWords",
      //   countNotifyNextOperation: "countNotifyNextOperationWords",
      //   countExcerptStocks: "countExcerptStocksWords",
      //   countReferenceOther: "countReferenceOtherWords",
      // }

      // Object.keys(concatFields).forEach(field => {
      //   if (data[field]) {
      //     data[field] = `${data[field]} ${data[concatFields[field]]}`;
      //   }
      // })

      return axios.post(`${config.host}/api/kiosk`, {
        data,
        productId: this.serviceId
      });
    },
    async retry() {
      this.hasError = false;
      try {
        let res = await this.loadDocument();
        console.log(res);
        this.document = res.data.document;
        this.setId(res.data.document.split("/")[4]);
        if (typeof res.data.payment !== 'undefined' && typeof res.data.qrcode !== 'undefined') {
          this.setPaymentPath(res.data.payment);
          this.setQrcodePath(res.data.qrcode);
          this.setPriceState();
        } else {
          this.setPaymentPath(false);
          this.setQrcodePath(false);
          this.setFreeState();
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.error = error;
        this.hasError = true;
        this.loading = false;
      }
    }
  },
  async mounted() {
    try {
      let res = await this.loadDocument();
      console.log(res);
      this.document = res.data.document;
      this.setId(res.data.document.split("/")[4]);
      if (typeof res.data.payment !== 'undefined' && typeof res.data.qrcode !== 'undefined') {
        this.setPaymentPath(res.data.payment);
        this.setQrcodePath(res.data.qrcode);
        this.setPriceState();
      } else {
        this.setPaymentPath(false);
        this.setQrcodePath(false);
        this.setFreeState();
      }

      this.loading = false;
    } catch (error) {
      console.log(error.message);
      this.error = error;
      this.hasError = true;
      this.loading = false;
    }
  }
};
</script>
