<template lang="pug">
  .order
    .order__body
      .order__alert Если Вы не видите в окне свою анкету, 
        a(
          href='#'
          @click.prevent='reload'
        ) нажмите сюда
      a.order__cover(
        :href='document'
        target='_blank'
        @click.prevent='openModal'
      )
        Loader(v-if='loading')
        .order__overlay
        embed.order__document(
          :src="preview"
        )
        div.order__document--placeholder
    .order__footer
      button.order__button.button.is-large.has-arrow(
        @click='clickHandler'
      ) Все верно, продолжить
</template>

<script>
import { mapActions } from "vuex";
import config from "@/config.js";

export default {
  data() {
    return {
      loading: false,
    }
  },
  props: {
    document: String
  },
  computed: {
    preview()
    {
      //console.log( this )
      return `https://view.officeapps.live.com/op/embed.aspx?src=${config.remoteHost}${this.document}&embedded=true`
    }
  },
  methods: {
    ...mapActions({
      setModalContent: "modal/set",
      setCompleted: "stages/setCompleted"
    }),
    openModal() {
      this.setModalContent(
        `<div style="position:relative;"><embed src="${this.preview}" style="width:100%;height:100%;border:none;"></iframe><div class="order__document--placeholder"></div></div>`
      );
    },
    clickHandler() {
      this.setCompleted("varification");
      this.$router.push("pay");
    },
    reload() {
      let embed = document.querySelector('.order__document');
      let newEmbed = document.createElement('embed');
      newEmbed.src = this.preview;
      newEmbed.classList.add('order__document');
      embed.parentElement.replaceChild( newEmbed, embed );
      this.loading = true;
      setTimeout( () => this.loading = false, 500 );
    }
  }
};
</script>

<style lang="scss">
.order {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__overlay {
    position: absolute;
    width: 600px;
    height: 200px;
  }

  .loader {
    position: absolute;
  }

  &__body {
    flex-grow: 1;
    width: 100%;
  }

  &__cover {
    display: block;
    width: calc(100% + 36px);
    height: 100%;
    margin: -18px;
    margin-bottom: 0;
    position: relative;
    z-index: 0;

    &::after {
      content: "";
      display: block;
      width: 64px;
      height: 64px;
      position: absolute;
      left: 30px;
      bottom: 40px;
      z-index: 1;
      border-radius: 14px;
      background-color: $accent;
      background-image: url(../img/zoom.svg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 32px 33px;
      transition: ease background-color 200ms;
    }

    &:hover {
      &::after {
        background-color: lighten($accent, 10);
      }
    }
  }

  &__document {
    width: 100%;
    height: 100%;
    min-height: 840px;
    border: none;
    &--placeholder {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 26px;
      background-color: #fff;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 130px;
    z-index: 99999;
  }

  &__alert {
    padding: 15px;
    padding-right: 15px;
    background-color: rgba(59,122,190,.2);
    border-radius: 14px;
    color: black;
    margin-bottom: 45px;
    a {
      color: $second;
    }
  }

  &__button {
  }
}
</style>
