<template lang="pug">
  DynamicModal(
    :open='true'
    :okBtn='false'
    size='xs'
  )
    .inactivity-alert
      .inactivity-alert__header {{ errorName }}
      .inactivity-alert__body(
        v-html='errorMessage'
      )
      .inactivity-alert__footer
        Button(
          label='Выйти на главный экран'
          :action='() => {this.$router.push("services");}'
          :classes="['is-bordered']"
        )
        Button(
          label='Повторить запрос'
          :action='retry'
        )
</template>

<script>
import DynamicModal from "@/components/DynamicModal.vue";
import Button from "@/components/Button.vue";

export default {
  props: {
    error: {
      type: [Object, String, Error],
      required: true
    },
    retry: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    Button,
    DynamicModal
  },
  computed: {
    errorName() {
      let name;
      switch (this.error.message) {
        case "Network Error":
          name = "Ошибка соединения с сервером";
          break;
        case "Request failed with status code 404":
          name = "Ошибка 404";
          break;
        case "Request failed with status code 500":
          name = "Внутренняя ошибка сервера";
          break;
        default:
          name = "Неизвестная ошибка";
          break;
      }
      return name;
    },
    errorMessage() {
      let message;
      switch (this.error.message) {
        case "Network Error":
          message = `
                    <p>Обратитесь к администратору, наблюдется потеря связи с Интернетом.</p>
                    <p>Также возможно, что сервер приложения недоступен.</p>
                    <br>
                    <p>Детали ошибки:</p>
                    <code>${this.error}<br>${this.error.stack}</code>
                    `;
          break;
        case "Request failed with status code 404":
          message = `
            <p>Обратитесь к администратору, запрашиваемая приложением страница не найдена на сервере приложения.</p>
            <br>
            <p>Детали ошибки:</p>
            <code>${this.error}<br>${this.error.stack}</code>
          `;
          break;
        case "Request failed with status code 500":
          message = `
            <p>Обратитесь к администратору, произошла внутренняя ошибка сервера приложения.</p>
            <br>
            <p>Детали ошибки:</p>
            <code>${this.error}<br>${this.error.stack}</code>
          `;
          break;
        default:
          message = `
                    <p>Произошла неизвестная ошибка приложения</p>
                    <p>Обратитесь к системному администратору.</p>
                    <br>
                    `;
          break;
      }
      return message;
    }
  },
  mounted() {
    console.log(this.error);
  }
}
</script>

<style lang="scss">
  code {
    display: inline-block;
    border: 1px solid rgb(146, 28, 28);
    background: #fc8989;
    padding: 15px;
    margin-bottom: 35px;
    font-weight: bold;
  }
</style>
